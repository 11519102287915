@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.MuiPaginationItem-root.Mui-selected{
  border: 2px solid rgb(112 166 179 / var(--tw-bg-opacity)) !important;
  color:  rgb(112 166 179 / var(--tw-bg-opacity)) !important;
  background: #fff !important;
}

.MuiButtonBase-root-MuiPaginationItem-root{
  background:  rgb(112 166 179 / var(--tw-bg-opacity)) !important;
  color: #fff !important;
}
.MuiPaginationItem-previousNext{
  background:  rgb(112 166 179 / var(--tw-bg-opacity)) !important;
  color: #fff !important;
}

.admin-card .MuiTypography-root{
  color: #fff !important
}

.ant-picker-content {
  border: none !important;
}

.MuiAppBar-root{
  background: #408999 !important;
}